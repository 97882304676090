import {DirectiveBinding, DirectiveOptions} from "vue/types/options";
import {VNode} from "vue";

class TailorColorDirective implements DirectiveOptions {
    static setStyle(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode) {
        // @ts-ignore
        let primary_colour = vnode.context.$store.state.Tailor.primary_colour;
        // @ts-ignore
        let secondary_colour = vnode.context.$store.state.Tailor.secondary_colour;

        if (vnode.componentOptions.tag === "v-btn") {
            if(vnode.componentOptions.propsData.hasOwnProperty('outlined')) {
                el.style.color = primary_colour;
            } else {
                el.style.color = secondary_colour;
                el.style.backgroundColor = primary_colour;
            }
        }
    }

    bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {
        // @ts-ignore
        el.__tailor_color_unwatch__ = vnode.context.$store.watch((state) => state.Tailor.primary_colour, primary_colour => {
            TailorColorDirective.setStyle(el, binding, vnode, oldVnode);
        });

        // @ts-ignore
        if(vnode.context.$store.state.Tailor.primary_colour ?? false) {
            TailorColorDirective.setStyle(el, binding, vnode, oldVnode);
        }
    }

    unbind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {
        // @ts-ignore
        el.__tailor_color_unwatch__ && el.__tailor_color_unwatch__();
    }
}

export default {
    install(Vue) {
        Vue.directive('tailor-color', new TailorColorDirective);
    },
}

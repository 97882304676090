import { Inertia, shouldIntercept } from '@inertiajs/inertia'

export default {
    install(Vue) {
        Vue.prototype.$config = {
            SHOP_URL: 'https://shop.34.105.167.112.nip.io',
            shopUrl: function(url) {
                return `${this.SHOP_URL}/${url}`;
            }
        };
    },
}

import PortalVue from "portal-vue";
import InertiaVuetifyDirective from "./Plugins/VueInertiaDirective";
import vuetify from "./Plugins/vuetify";
import VueConfig from "./Plugins/VueConfig";
import TailorColorDirective from "./Plugins/TailorColorDirective";



let Plugins = {
    install(Vue) {
        Vue.use(PortalVue);
        Vue.use(InertiaVuetifyDirective);
        Vue.use(VueConfig);
        Vue.use(TailorColorDirective);
    }
};

export {Plugins, vuetify};

<template>
    <v-app>
        <!-- Header -->
        <main-nav :navigation-drawer.sync="navigationDrawer" />

        <!-- Page Content -->

        <div class="tw-flex">
            <tailor-add-address />

            <!-- Nav -->
            <div
                v-if="$page.props.user"
                :class="{ 'tw-hidden': !navigationDrawer }"
                class="tw-fixed tw-z-20 tw-min-h-screen md:tw-static tw-w-full md:tw-w-1/6 tw-bg-secondary tw-p-4"
                style=""
            >
                <div class=" logo tw-mt-8 tw-text-center">
                    <v-img
                        :src="`../storage/${$page.props.tailor.logo}`"
                        class=" tw-mx-auto"
                        style="width: 80%"
                    />
                </div>

                <v-list dense nav class="tw-mt-6" color="transparent">
                    <v-list-item
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('profile.show').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                                >Your Profile
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <v-list-item
                        v-if="$page.props.isTailor"
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('tailor.index').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                                >Your Tailor Information
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <v-list-item
                        v-if="$page.props.isTailor"
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('discounts.index').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                                >Your Discounts
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <v-list-item
                        v-if="
                            $page.props.isTailor &&
                                $page.props.tailor.stripe !== null
                        "
                        link
                        target="_blank"
                        color="white"
                        class="tw-py-2"
                        :href="route('stripeLogin').url()"
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                            >
                                Your Payments
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <!-- <v-list-item
                        v-if="$page.props.isTailor"
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('vendors.index').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                            >
                                Your Vendors
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item> -->

                    <v-list-item
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('customer.index').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                                >Your Customers
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <!-- <v-list-item
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('marketing.index').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                                >Your Marketing
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item> -->

                    <v-list-item
                        v-if="$page.props.isTailor"
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('widget.index').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                            >
                                Your Widget
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <!-- <v-list-item v-if="$page.props.isTailor" link color="white" class="tw-py-2" :href="route('suitbuilder-options.index').url()" v-inertia>
                        <v-list-item-content>
                            <v-list-item-title class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest">
                                Your Suit-builder options
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item> -->

                    <v-list-item
                        v-if="$page.props.isTailor"
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('api-tokens.index').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                            >
                                Your API Tokens
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <!--                    <v-list-item v-if="$page.props.isTailor" link color="white" class="tw-py-2" :href="route('collections.index').url()" v-inertia>-->
                    <!--                        <v-list-item-content>-->
                    <!--                            <v-list-item-title class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest">Your-->
                    <!--                                Collections-->
                    <!--                            </v-list-item-title>-->
                    <!--                        </v-list-item-content>-->
                    <!--                        <v-list-item-icon>-->
                    <!--                            <v-icon color="menuText">mdi-chevron-down</v-icon>-->
                    <!--                        </v-list-item-icon>-->
                    <!--                    </v-list-item>-->

                    <v-list-item
                        v-if="$page.props.isTailor"
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('pricing.index').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                                >Your Pricing
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <v-list-item
                        link
                        color="white"
                        class="tw-py-2"
                        :href="route('orders.index').url()"
                        v-inertia
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="tw-text-menuText tw-text-sm tw-uppercase tw-tracking-widest"
                                >Your Orders
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon color="menuText">mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
            </div>

            <!-- Main -->
            <div class="tw-w-full">
                <div>
                    <v-main>
                        <slot></slot>
                    </v-main>
                </div>
            </div>

            <!-- Modal Portal -->
            <portal-target name="modal" multiple> </portal-target>
        </div>
    </v-app>
</template>

<script>
import ApplicationLogo from "@/Jetstream/ApplicationLogo";
import ApplicationMark from "@/Jetstream/ApplicationMark";
import NotificationDrawer from "@/Components/NotificationDrawer";
import MainNav from "./Components/MainNav";
import TailorAddAddress from "@/Components/TailorAddAddress";

export default {
    name: "MainLayout",
    components: {
        TailorAddAddress,
        MainNav,
        NotificationDrawer,
        ApplicationMark,
        ApplicationLogo
    },
    data: () => ({
        navigationDrawer: true
    }),
    mounted() {
        if (
            this.$vuetify.breakpoint.name === "sm" ||
            this.$vuetify.breakpoint.name === "xs"
        ) {
            this.navigationDrawer = false;
        }
    },
    methods: {
        logout() {
            axios.post(route("logout").url()).then(response => {
                window.location = "/";
            });
        }
    }
};
</script>

<style scoped></style>




































































import {Component, PropSync, Vue} from "vue-property-decorator";
import ApplicationLogo from "@/Jetstream/ApplicationLogo";
import ApplicationMark from "@/Jetstream/ApplicationMark";
import NotificationDrawer from "@/Components/NotificationDrawer";
import axios from "axios";

@Component({
    components: {
        NotificationDrawer,
        ApplicationMark,
        ApplicationLogo
    }
})
export default class MainNav extends Vue {
    @PropSync('navigationDrawer')
    computedNavigationDrawer!: boolean;

    mounted() {
        if(this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs'){
            this.navigationDrawer = false
        }
    }

    leaveImpersonation()
    {
        this.$inertia.visit(route('leaveImpersonation'))
    }

    logout() {
        axios.post(route('logout').url()).then(response => {
            window.location = "/";
        })
    }
}

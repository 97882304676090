<template>
    <div>
        <v-dialog v-model="show" max-width="750" persistent>
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-alert type="info">
                        Please enter your address to continue.
                    </v-alert>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="form.telephone" label="Phone Number" outlined :error-messages="errors.telephone" :hide-details="errors.telephone === undefined" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="form.address_line_1" label="Address Line 1" outlined :error-messages="errors.address_line_1" :hide-details="errors.address_line_1 === undefined" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="form.address_line_2" label="Address Line 2" outlined :error-messages="errors.address_line_2" :hide-details="errors.address_line_1 === undefined" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="form.city" label="City" outlined :error-messages="errors.city" :hide-details="errors.city === undefined" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="form.state" label="State" outlined :error-messages="errors.state" :hide-details="errors.state === undefined" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="form.post_code" label="Post Code" outlined :error-messages="errors.post_code" :hide-details="errors.post_code === undefined" />
                        </v-col>
                        <v-col cols="12">
                            <country-drop-down v-model="form.country" />
                        </v-col>
                        <v-col cols="12">
                            <v-btn @click="saveAddress" color="primary" width="100%" height="52px">
                                Save Address
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="success" :timeout="3000">
            Address Updated
        </v-snackbar>
    </div>
</template>

<script>
import CountryDropDown from "@/Components/CountryDropDown";
export default {
    name: "TailorAddAddress",
    components: {CountryDropDown},
    data() {
        return {
            show: !this.$page.props.addressSet,
            form: this.$inertia.form({
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                post_code: '',
                country: '',
                telephone: '',
            }),
            errors: {},
            success: false,
        }
    },
    methods: {
        saveAddress() {
            this.form.post(route('tailor.address'), {
                onSuccess: () => {
                    this.success = true;
                    this.show = false;
                }
            });
        }
    }
}
</script>

<style scoped>

</style>

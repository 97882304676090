<template>
    <div class="tw-inline">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    large
                >
                    <v-badge
                        v-if="$page.props.unreadNotifications.length > 0"
                        color="white"
                        offset-y="10"
                    >
                        <template v-slot:badge>
                            <span class="tw-text-primary">
                                {{$page.props.unreadNotifications.length}}
                            </span>
                        </template>
                        <v-icon>
                            mdi-bell
                        </v-icon>
                    </v-badge>
                    <v-icon v-else>
                        mdi-bell
                    </v-icon>

                </v-btn>
            </template>
            <v-card>
                <v-list>
                    <v-subheader>Notifications</v-subheader>
                    <v-divider></v-divider>

                    <!-- No Notification message -->
                    <v-list-item v-if="$page.props.unreadNotifications.length === 0">
                        <v-list-item-title>
                            <v-btn text>
                                You have no notifications
                            </v-btn>
                        </v-list-item-title>
                        <v-list-item-action>
                        </v-list-item-action>
                    </v-list-item>
                        <!-- Notifications -->
                    <v-list-item v-for="notification in $page.props.unreadNotifications" :key="notification.id" class="text-wrap">
                        <v-list-item-title>
                            <div v-if="$page.props.isAdmin">
                                <!-- Account Manager Notification -->
                                <span v-if="notification.type.includes('AccountManagerNotification')" >
                                    <v-btn
                                        text
                                        :href="route('admin.customer.show', {id: notification.data.customer_id})"
                                    >
                                        You have been assigned a new customer
                                    </v-btn>
                                </span>

                                <!-- Task Notification -->
                                <span v-if="notification.type.includes('TaskAssignedNotification')" class="tw-break-all">
                                    <v-btn
                                        text
                                        :href="route('admin.customer.show', {id: notification.data.customer.id})"
                                    >
                                        You have been assigned a new task '{{ notification.data.task.title }}'
                                    </v-btn>
                                </span>
                                <!-- Order Notification -->
                                <span v-if="notification.type.includes('CMTOrderNotification')" class="tw-break-all">
                                    <v-btn
                                        text
                                        :href="route('admin.orders.show', {id: notification.data.order_id})"
                                    >
                                        <span v-if="notification.data.cmt">
                                             You have a new {{ notification.data.cmt }} Order '{{ notification.data.order_id }}'
                                        </span>
                                        <span v-else>
                                            You have a new CMT Order '{{ notification.data.order_id }}'
                                        </span>
                                    </v-btn>
                                </span>
                            </div>
                            <div v-else>
                                <!-- Account Manager Notification -->
                                <span v-if="notification.type.includes('AccountManagerNotification')" >
                                    <v-btn
                                        text
                                        :href="route('customer.show', {id: notification.data.customer_id})"
                                    >
                                        You have been assigned a new customer
                                    </v-btn>
                                </span>

                                <!-- Task Notification -->
                                <span v-if="notification.type.includes('TaskAssignedNotification')" class="tw-break-all">
                                    <v-btn
                                        text
                                        :href="route('customer.show', {id: notification.data.customer.id})"
                                    >
                                        You have been assigned a new task '{{ notification.data.task.title }}'
                                    </v-btn>
                                </span>
                                <!-- Order Notification -->
                                <span v-if="notification.type.includes('CMTOrderNotification')" class="tw-break-all">
                                    <v-btn
                                        text
                                        :href="route('orders.show', {id: notification.data.order_id})"
                                    >
                                        <span v-if="notification.data.cmt">
                                             You have a new {{ notification.data.cmt }} Order '{{ notification.data.order_id }}'
                                        </span>
                                        <span v-else>
                                            You have a new CMT Order '{{ notification.data.order_id }}'
                                        </span>
                                    </v-btn>
                                </span>
                            </div>
                        </v-list-item-title>
                        <v-list-item-action>
                            <v-btn icon color="primary" title="Mark as read" @click="markAsRead(notification.id)">
                                <v-icon>
                                    mdi-check
                                </v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>

        <v-snackbar v-model="updated" :timeout="1000">
            Notification marked as read
        </v-snackbar>
    </div>

</template>

<script>
export default {
    name: "NotificationDrawer",
    data() {
        return {
            updated: false
        }
    },
    methods: {
        markAsRead(notificationId) {
            this.$inertia.form({
                'notificationId': notificationId
            }).post(route('notificationRead'), {
                preserveScroll: true,
                onSuccess: () => {
                    this.updated = true;
                }
            })
        }
    }
}
</script>

<style scoped>

</style>

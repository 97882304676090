import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#911215',
                secondary: '#333333',
                ghostwhite: '#F5F9FF',
                white: '#FFFFFF',
                accent: '#333333',
                menuText: '#BDBDBD',
            },
        },
    }
})
